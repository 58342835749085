import apiClient from '@/services/axios'

export async function getData(page, perPage, columns, filter, order, logType) {
  const path = `/log/csv?log_type=[${logType}]`
  return apiClient.post(path, {
    Page: page,
    PerPage: perPage,
    Columns: columns,
    Order: order.filter(el => columns.includes(el.Column)),
    Filter: filter,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function exportData(columns, filter, order, logType) {
  const path = `/log/csv?log_type=${logType}`
  console.log(path, 'Path')
  return apiClient.post(path, {
    Filter: filter,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
