<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
    <h5 class="my-3"><strong>LOG</strong></h5>
    <div class="log-header-flex">
      <div>
        <a-col class="my-2 log-type">
          <div class="mr-2">Log Type:</div>
          <a-radio-group v-model="logType">
            <a-radio-button :value="1">Redirect</a-radio-button>
            <a-radio-button :value="2">IP Mismatch</a-radio-button>
            <a-radio-button :value="3">User Agent Mismatch</a-radio-button>
            <a-radio-button :value="4">Referer Mismatch</a-radio-button>
          </a-radio-group>
        </a-col>
        <a-col class="my-2 custom-report-input-wrapper">
          <a-select mode="single" allowClear placeholder="Campaign" :showSearch="true" :filterOption="true"
            optionFilterProp="children" v-model="filteredColumns.selectedCampaigns.Values">
            <a-select-option v-for="obj in ortbCampaign.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
              }}</a-select-option>
          </a-select>
          <a-button @click="addCampaigns"><i class="fa fa-user"></i></a-button>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="Ad" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.ads.Values">
            <a-select-option v-for="obj in ad.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="Remote RTB Zone" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.remotertbzones.Values">
            <a-select-option v-for="obj in remotertbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
              }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="Remote Video Zone" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.remotevideozones.Values">
            <a-select-option v-for="obj in remotevideozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
              }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="DSP Pop Feed" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.dsppopfeeds.Values">
            <a-select-option v-for="obj in dsppopfeed.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
              }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="Banner Zone" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.bannerzones.Values">
            <a-select-option v-for="obj in bannerzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
              }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="Video Zone" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.videozones.Values">
            <a-select-option v-for="obj in videozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="RTB Zone" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.rtbzones.Values">
            <a-select-option v-for="obj in rtbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col class="my-2">
          <a-select mode="single" allowClear placeholder="Pop Feed" style="width: 100%" :showSearch="true"
            :filterOption="true" optionFilterProp="children" v-model="filteredColumns.popfeeds.Values">
            <a-select-option v-for="obj in popfeed.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
              }}</a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col>
        <a-input v-model="filteredColumns.error.Contains" placeholder="Error" style="width: 100%" />
      </a-col> -->
        <a-col class="my-2">
          <div style="display: flex; justify-content: space-between">
            <a-range-picker :defaultValue="this.ranges['Today']" @change="setDateRange" :ranges="ranges"
              :allowClear="false" style="width: 100%" />
            <!-- <a-button type="primary" class="ml-2" @click="setFilters" style="width: 100%">
            Apply
          </a-button> -->
            <b-button size="sm" class="btn-info mr-1" @click="exportToCsv" title="Export to CSV"
              style="margin-left: 7px; width: 100%; border-radius: 5px;">
              <i class="fa fa-file-excel-o"></i>
              Export to CSV
            </b-button>
          </div>
        </a-col>
      </div>
      <!-- <b-row class="mt-2">
        <b-col md="6" class="my-1">
          <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
            <perPageSelect v-model="perPage" :storageKey="'ortb-reports-log'" :pageOptions="pageOptions" />
            <columnSelect :storageKey="'display-reports-log-columns'" :options="fields"></columnSelect>
          </b-form-group>
        </b-col>
      </b-row> -->
    </div>
    <!-- Main table element -->
    <!-- <b-table sort-by="Timestamp" :sort-desc="true" @sort-changed="sortingChanged" ref="table" show-empty :small="true" :bordered="true" :responsive="true"
      :striped="true" :hover="true" stacked="md" :items="getTableData" :fields="filteredFields"
      :current-page="currentPage" :per-page="perPage" :no-local-sorting="true" :sort-direction="sortDirection"
      @filtered="onFiltered" :busy="isBusy">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
<template #cell(CC)="data">
        <div><img :src="matchIcon(countries, data.item.CC)" /> {{ data.item.CC }}</div>
      </template>
<template #cell(RemotertbzoneName)="data">
        <div v-if="data.item.RemotertbzoneId && data.item.RemotertbzoneId !== 'N/A'">
          <router-link :to="editZone(data.item.RemotertbzoneId, 'remotertbzone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.RemotertbzoneName }}
          </router-link>
        </div>
        <div v-else>{{data.item.RemotertbzoneName}}</div>
      </template>
<template #cell(RemotertbzoneId)="data">
          <router-link v-if="data.item.RemotertbzoneId !== 'N/A'" :to="editZone(data.item.RemotertbzoneId, 'remotertbzone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.RemotertbzoneId }}
          </router-link>
          <div v-else>
            N/A
          </div>
      </template>
<template #cell(DsppopfeedName)="data">
        <div v-if="data.item.DsppopfeedId && data.item.DsppopfeedId !== 'N/A'">
          <router-link :to="editZone(data.item.DsppopfeedId, 'dsppopfeed')" target="_blank" class="table-cursor-pointer">
          {{ data.item.DsppopfeedName }}
          </router-link>
        </div>
        <div v-else>{{data.item.DsppopfeedName}}</div>
      </template>
<template #cell(DsppopfeedId)="data">
          <router-link v-if="data.item.DsppopfeedId !== 'N/A'" :to="editZone(data.item.DsppopfeedId, 'dsppopfeed')" target="_blank" class="table-cursor-pointer">
          {{ data.item.DsppopfeedId }}
          </router-link>
          <div v-else>
            N/A
          </div>
      </template>
<template #cell(RemotevideozoneName)="data">
        <div v-if="data.item.RemotevideozoneId && data.item.RemotevideozoneId !== 'N/A'">
          <router-link :to="editZone(data.item.RemotevideozoneId, 'remotevideozone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.RemotevideozoneName }}
          </router-link>
        </div>
        <div v-else>{{ data.item.RemotevideozoneName }}</div>
      </template>
<template #cell(RemotevideozoneId)="data">
          <router-link v-if="data.item.RemotevideozoneId !== 'N/A'" :to="editZone(data.item.RemotevideozoneId, 'remotevideozone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.RemotevideozoneId }}
          </router-link>
          <div v-else>
            N/A
          </div>
      </template>
<template #cell(BannerzoneName)="data">
        <div v-if="data.item.BannerzoneId && data.item.BannerzoneId !== 'N/A'">
          <router-link :to="editZone(data.item.BannerzoneId, 'bannerzone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.BannerzoneName }}
          </router-link>
        </div>
        <div v-else>{{ data.item.BannerzoneName }}</div>
      </template>
<template #cell(BannerzoneId)="data">
          <router-link v-if="data.item.BannerzoneId !== 'N/A'" :to="editZone(data.item.BannerzoneId, 'bannerzone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.BannerzoneId }}
          </router-link>
          <div v-else>
            N/A
          </div>
      </template>
<template #cell(VideozoneName)="data">
        <div v-if="data.item.VideozoneId && data.item.VideozoneId !== 'N/A'">
          <router-link :to="editZone(data.item.VideozoneId, 'videozone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.VideozoneName }}
          </router-link>
        </div>
        <div v-else>{{ data.item.VideozoneName }}</div>
      </template>
<template #cell(VideozoneId)="data">
          <router-link v-if="data.item.VideozoneId !== 'N/A'" :to="editZone(data.item.VideozoneId, 'videozone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.VideozoneId }}
          </router-link>
          <div v-else>
            N/A
          </div>
      </template>
<template #cell(RtbzoneName)="data">
        <div v-if="data.item.RtbzoneId && data.item.RtbzoneId !== 'N/A'">
          <router-link :to="editZone(data.item.RtbzoneId, 'rtbzone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.RtbzoneName }}
          </router-link>
        </div>
        <div v-else>{{ data.item.RtbzoneName }}</div>
      </template>
<template #cell(RtbzoneId)="data">
          <router-link v-if="data.item.RtbzoneId !== 'N/A'" :to="editZone(data.item.RtbzoneId, 'rtbzone')" target="_blank" class="table-cursor-pointer">
          {{ data.item.RtbzoneId }}
          </router-link>
          <div v-else>
            N/A
          </div>
      </template>
<template v-if="!loading" #custom-foot>
        <b-tr>
          <b-td v-for="col in filteredFields" :key="col.key">
            <div v-if="col.summed">
              <p v-if="col.formatter === '$formatNumber'" class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(sum[col.key]) }}
              </p>
              <p v-else class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', {
                  style: 'currency', currency: 'USD', maximumFractionDigits: 2
                }).format(sum[col.key]) }}
              </p>
            </div>
          </b-td>
        </b-tr>
      </template>
</b-table> -->
    <!-- <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
          (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
            perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
// import columnSelect from '@/components/custom/tableColumnSelect'
// import perPageSelect from '@/components/custom/PerPageSelect'
import logFields from './columns/logFields'
import { getData, exportData } from '@/api/display/reports_log'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import staticCountryData from '@/resources/countries.json'
import Vue from 'vue'

export default {
  name: 'reports',
  components: {},
  data() {
    return {
      countries: staticCountryData,
      logType: 1,
      reports: [],
      sortDirection: 'desc',
      userId: this.$store.state.auth.id,
      dateRange: {
        Column: 'DateStats',
        From: '',
        To: '',
        Values: [],
      },
      selectedDate: [moment().startOf('month'), moment().endOf('month')],
      filteredColumns: {
        selectedCampaigns: {
          Column: 'CampaignId',
          Values: [],
        },
        ads: {
          Column: 'AdId',
          Values: [],
        },
        remotevideozones: {
          Column: 'RemotevideozoneId',
          Values: [],
        },
        dsppopfeeds: {
          Column: 'DsppopfeedId',
          Values: [],
        },
        popfeeds: {
          Column: 'PopfeedId',
          Values: [],
        },
        bannerzones: {
          Column: 'BannerzoneId',
          Values: [],
        },
        videozones: {
          Column: 'VideozoneId',
          Values: [],
        },
        rtbzones: {
          Column: 'RtbzoneId',
          Values: [],
        },
        remotertbzones: {
          Column: 'RemotertbzoneId',
          Values: [],
        },
      },
      filters: [],
      thisMonth: [moment().startOf('month'), moment().endOf('month')],
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      loading: false,
      moment: moment,
      isBusy: false,
      tableData: [],
      fields: logFields,
      sum: {},
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      order: [{ Column: 'Timestamp', Ord: 'ASC' }],
      total: 0,
    }
  },
  created() {
    this.$store.dispatch('remotertbzone/LOAD_ZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
    this.$store.dispatch('remotevideozone/LOAD_ZONES_SHORT')
    this.$store.dispatch('dsppopfeed/LOAD_FEEDS_SHORT')
    this.$store.dispatch('popfeed/LOAD_FEEDS_SHORT')
    this.$store.dispatch('bannerzone/LOAD_BANNERZONES_SHORT')
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_SHORT')
    this.$store.dispatch('ad/LOAD_ADS_SHORT')
    this.setDateRange(this.ranges.Today)
  },
  computed: {
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.total
    },
    ...mapState(['ortbCampaign', 'remotertbzone', 'rtbzone', 'ad', 'remotevideozone', 'bannerzone', 'videozone', 'dsppopfeed', 'popfeed']),
    ...mapGetters('ortbCampaign', ['getActiveORTBCampaigns']),
  },
  methods: {
    // editZone(id, remote) {
    //   if (!id) {
    //   } else if (remote) {
    //     return `/remotertbzone/update/${id}`
    //   } else {
    //     const path = remote ? `/remotertbzone/update/${id}` : `/rtbzone/update/${id}`
    //     return path
    //   }
    // },
    editZone(id, zone) {
      if (!id) {
      } else {
        const path = `/${zone}/update/${id}`
        return path
      }
    },

    addCampaigns() {
      this.filteredColumns.selectedCampaigns.Values = []
      this.ortbCampaign.list.forEach(el => {
        if (el.AccManagerId === this.userId && el.Status === 0) {
          this.filteredColumns.selectedCampaigns.Values.push(el.Id)
        }
      })
    },
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values && this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        } else if (this.filteredColumns[col].Contains && this.filteredColumns[col].Contains !== '') {
          array.push(this.filteredColumns[col])
        }
      }
      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        array.push(this.dateRange)
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    exportToCsv() {
      // Dynamically build filters using the same logic as setFilters
      const filters = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values && this.filteredColumns[col].Values.length !== 0) {
          filters.push(this.filteredColumns[col])
        } else if (this.filteredColumns[col].Contains && this.filteredColumns[col].Contains !== '') {
          filters.push(this.filteredColumns[col])
        }
      }

      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        filters.push(this.dateRange)
      }

      // Ensure at least two filters are selected
      if (filters.length < 2) {
        Vue.prototype.$notification.warning({
          message: 'Please select more than 2 filters.',
          description: 'You need to select at least two filters to export to CSV.',
        })
        return
      }

      // Get the visible columns
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)

      // Call exportData with the dynamically created filters
      exportData(columns, filters, this.order, this.logType).then(response => {
        const blob = new Blob([response], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `reports_log_${moment().format('YYYY-MM-DD')}.csv`
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        console.error('Error exporting data:', error)
      })
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    setDateRange(date) {
      this.filters = this.filters.filter(ele => ele.Column !== 'Date')
      this.dateRange.From = date[0] ? date[0].format('YYYY-MM-DD') : ''
      this.dateRange.To = date[1] ? date[1].format('YYYY-MM-DD') : ''
      this.filters.push(this.dateRange)
    },
    getTableData(ctx, callback) {
      this.loading = true
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.sum = response.Sum
        this.total = response.Total
        this.loading = false
        callback(response.Data)
      })
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callback(value) {
      return value
    },
  },
  watch: {
    fields: {
      handler(val) {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.custom-report-input-wrapper {
  display: flex;
  justify-content: center;
}

.custom-report-input-wrapper>.ant-select {
  width: 100%;
  margin-right: 2px;
}

.custom-report-input-wrapper>.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.log-header-flex {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.log-type {
  display: flex;
  align-items: center;
}

</style>
